.commission-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}
.matrix-save {
    min-width: 60px;
}

