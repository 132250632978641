.header-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 5fr 1fr;
    margin: 0 auto;
}
.nav-container {
    display: flex;
    justify-content: flex-end;
    
}
.logo > span {
    text-transform: lowercase;
    font-size: 18px;
}
.logo {
    margin: 0 auto;
    
}