.customer-text {
    margin-bottom: 3px;
    margin-right: 2em;
    min-width: 1fr;
}
/* div.customer {
    display: flex;
    justify-content: space-between; */
    /* display: grid;
    grid-template-rows: repeat(8,1fr);
    
} */