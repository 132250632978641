.title {
    margin-top:1em;
    margin-bottom: 1em;
    font-size: 1.25em;
}
.analysis {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr;
    
}
.analysis-header, .analysis-footer {
    font-weight: bold;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}
.left-align {
    align-items: left;
}

.right-align {
    width: 100%;
    text-align: right;
}
a {
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
  }
  .analysis-row:hover {
      background-color: lightgrey;
  }
  .analysis {
      font-size: 14px;
      white-space: nowrap;
  }

