nav {
    display: flex;
    
}

nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0;
}

nav li {
    margin-right: 1em;
    margin-left: 2em;
    margin-bottom: 0;
}

form {
    margin-top: 3em;
}
label {
    margin-top: 1em;
    margin-right: 1em;
}
.logout {
    font-size: 14px;
    margin: auto 1em;
    cursor: pointer;
}