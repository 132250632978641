.allocation-display-row {
    margin-top: 0.5em;
}
.delete-allocation-button {
    margin-left: 2em;
    font-size: 12px;;
}
.allocation-amount-display {
    margin-left: 2em;
    min-width: 20em;
}

.allocation-receipt-ref-display {
    margin-left: 2em; 
    min-width: 20em;
}
.allocation-receipt-date-display {
    margin-left: 2em;
}