.item-list {
    padding-right: 0px;
    display: block;
    margin: 0;
    list-style-type: disc;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;

}
.list-container {
    padding: 0;
}