li.invoice {
    list-style: none;
    border-bottom: 2px solid black;
    display: grid;
    grid-template-columns: 10ch 1fr 15ch;
}

li.invoice > div > div {
    display: inline-block;
    padding-left: 1ch;
    overflow: hidden;
    /* outline: 1px solid black; */
    white-space: nowrap;
}

li.invoice.selected {
    background-color: darkblue;
    color: white;
}

li.invoice:nth-child(odd):not(.selected) {
    background-color: #DDD;
}

div.saleshouse {
    display: none;
}

div.agency {
    width: 15ch;
}

div.grid-middle {
    display: inline-block;
    padding: 3px 0px;
    /* outline: 1px solid pink; */
}
/* <div className="saleshouse">{invoice.SalesHouseID}</div>
<div className="agency">{invoice.InvoiceAgencyId}</div>
<div className="contact">{invoice.ContactPerson}</div>
<div className="order">{invoice.OrderID}</div>
<div className="transaction-type">{invoice.TransactionType}</div>
<div className="date-field">Campaign Start: {startDate.toLocaleDateString("en-GB")}</div>
<div className="date-field">Campaign End: {endDate.toLocaleDateString("en-GB")}</div>
<div className="invoice-number">{invoice.InvoiceNumber}</div>
<div className="date-field">InvoiceDate: {invoice.InvoiceDate}</div>
<div className="order-category">{invoice.OrderCategory}</div>
<div className="advertiser">{invoice.Advertiser}</div>
<div className="salesperson">{invoice.SalesPerson}</div>
<div className="order-description">{invoice.OrderDescription}</div>
<div className="product">{invoice.Product}</div>
<div className="channel">{invoice.Channel}</div>
<div className="nett">{formatter.format(invoice.Nett)}</div>
<div className="commission">{formatter.format(invoice.AgencyCommissionValue)}</div>
<div className="credited-by">{invoice.CreditedBy}</div>
<div className="product-only">{invoice.ProductOnly}</div>
<div className="advertiser-for">{invoice.AdvertiserFor}</div>
<div className="sales-amount">{formatter.format(invoice.SalesInvoiceAmount)}</div>
<div className="purchase-amount">{formatter.format(invoice.PurchaseInvoiceAmount)}</div> */

div.advertiser-for {
    width: 20ch;
}
div.product-only {
    width: 20ch;
}
div.channel {
    width: 6ch;
}
div.product {
    width: 35ch;
    overflow: hidden;

}
div.order-description {
    overflow: hidden;
    width: 30ch;
    /* outline: 1px solid red; */
}
div.salesperson {
    width: 20ch;
    display: none;
}
div.sales.money {
    display: flex;
    align-items: center;
    padding-right: 10px;
    margin-right:10px;
    text-align: right;
    width: 100%;
    /* outline: 1px solid green; */
    justify-content: right;
}

div.money {
    width: 14ch;
}

div.order-category {
    width: 10ch;
} 
div.invoice-number {
    padding-left: 10px;
    width: 8ch;
    display: flex;
    align-items: center;
}
div.date-field {
    width: 25ch;
}

div.order {
    width: 15ch;

}
div.contact {
    width: 20ch;
    display: none;
}
div.advertiser {
    width: 20ch;
}

div.action-panel {
    background-color: lightblue;
    grid-column: 1 / 4;
    display:flex;
}

button.mark-paid {
    margin: 2px auto;
}
