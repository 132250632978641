.allocation-builder {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    gap: 20px;
    padding-top: 10px;
}
.allocation-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    padding: 0 10px;
}

.allocation-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 10px;
    padding: 10px;
}
.allocation-button {
    cursor: pointer;
    
    font-weight: bold;
    width: 100%;
    text-align: center;
    border-radius: 10px;
    padding: 5px 0;
}
.clear-button {
    background-color: rgb(236, 92, 87);
}
.clear-button:hover {
    background-color: red;
}
.allocate-button {
    background-color: rgb(93, 223, 93);
}

.allocate-button:hover {
    background-color: rgb(35, 173, 35);
}

li.allocation-receipt-item {
    font-size: 14px;
}

.allocation-item-list {
    list-style-type: none;
}