input.owner-maintenance{
    min-width: 40ch;
    display: block;
    margin-bottom: 5px;
    outline: none;
    border: none;
    box-shadow: inset 0 0 5px lightgrey;
    padding: 5px;
    border-radius: 5px;
}

.supplier-submit-button {
    margin: 0 0 0 auto;
}