.customer-add {
    min-width: 12em;
}

.customer-info {
    margin-bottom: 3px;
    margin-right: 3px;
}
.customer-container {
    display: grid;
    grid-template-columns: 1fr 3fr 6fr;
}
.customer-container:hover {
    cursor: pointer;
    background-color: lightgrey;
}

.agency-name {
    text-align: left;
    overflow: hidden;
}
.contact-name {
    text-align: left;
    overflow: hidden;
}
.contact-email {
    text-align: left;
    overflow: hidden;
}
.address {
    text-align: left;
    /* background-color: lightyellow   ; */
    overflow: hidden;
}
