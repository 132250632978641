nav a {
    color: white;
    text-decoration: none;
    opacity: 1;
    position: relative;
}



nav a::before {
    content: '';
    display: block;
    height: 3px;
    background: white;
    position: absolute;
    top: 1.125em;
    width: 100%;
    transform: scale(0,1);
    transition: transform ease-in-out 250ms;
}
.nav-header.active a::before {
    content: '';
    display: block;
    height: 3px;
    background: white;
    position: absolute;
    top: -0.175em;
    width: 100%;
    
}

nav a:hover::before {
    transform: scale(1,1);
}