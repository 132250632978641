.receipt-invoice {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr 1fr 2fr 1fr;
    cursor: pointer;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
}
.receipt {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr;
    cursor: pointer;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
}
.receipt-header-titles, .invoice-receipt-header-titles {
    font-size: 12px;
    font-weight: bold;
}
.receipt-header-titles {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr;
    
}
.invoice-receipt-header-titles {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr 1fr 2fr 1fr;
    
}
.receipt:hover, .receipt-invoice:hover {
    background-color: lightgrey;
}

.receipt.selected, .receipt-invoice.selected {
    background-color: lightskyblue;
}
.receipt-container {
    position: relative;
    
}

.age-header {
    width: 100%;
    background-color: powderblue;
    text-align: center;
}

.with-sidebar {
    display: flex;
    flex-wrap: wrap;
    /* gap: var(--s1); */
  }
  
  .with-sidebar > :first-child {
    
    flex-basis: 0;
    flex-grow: 999;
    min-inline-size: 50%;
  }
  
  .with-sidebar > :last-child {
    flex-grow: 1;
  }

  .no-select {
    user-select: none; /* CSS3 (little to no support) */
    -ms-user-select: none; /* IE 10+ */
    -moz-user-select: none; /* Gecko (Firefox) */
    -webkit-user-select: none; /* Webkit (Safari, Chrome) */
}