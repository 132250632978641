div.error-message {
    color: red;
    width: 100%;
    text-align: center;
}

div.login-form {
    margin: 35px auto;
    /* padding: 100px; */
    display: grid;
    grid-template-columns: 1fr 200px 200px 1fr;
    column-gap: 10px;
    row-gap: 1em;
}
div.login-form > div {
    margin-bottom: 20px;
}
.btn-login {
    grid-column: 2 / 4;
}
.error-message {
    grid-column: 2/4;
}
.login-label {
    grid-column: 2/3;
}
.login-input {
    grid-column: 3/4
}