.search-result-receipt {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    cursor: pointer;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
}

.search-result-receipt-header-titles {
    font-size: 12px;
    font-weight: bold;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    
}

.search-result-receipt:hover {
    background-color: lightgrey;
}