.search-type-selector {
    
}

.search-active-selector {
    font-weight: bold;
    
}

.search-inactive-selector {
    background-color: lightgray;
    opacity: 60%;
}

.search-selector {
    display: inline-block;
    cursor: pointer;
    padding: 10px 30px;
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-radius: 10px 10px 0px 0px;
}