.payment-invoice {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 2fr;
    cursor: pointer;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
}
.payment-invoice.released {
    background-color: rgb(190, 250, 190);
}

.payment {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr;
    cursor: pointer;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
}

.payment.selected {
    background-color: lightskyblue;
}
.payments-container {
    position: relative;
}

.age-header {
    width: 100%;
    background-color: powderblue;
    text-align: center;
}


.payment-header-titles, .invoice-payment-header-titles {
    font-size: 12px;
    font-weight: bold;
}
.payment-header-titles {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr;
    
}
.invoice-payment-header-titles {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 2fr;
    
}
.payment:hover, .payment-invoice:hover {
    background-color: lightgrey;
}

.payment.selected, .payment-invoice.selected, .payment-invoice.released.selected {
    background-color: lightskyblue;
}
.payment-container {
    position: relative;
    
}


