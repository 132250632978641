.search-box {
    border: 1px solid black;
    padding: 10px;
    margin-bottom: 20px;
    gap: 10px;
}

.search-result-receipt-invoice-header-titles {

}

.search-receipt-invoice-header-titles {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr 1fr 2fr 2fr;
    font-size: 12px;
    font-weight: bold;
}
.search-criteria {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px 20px;
    align-items: flex-end;
}