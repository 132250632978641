.allocation-builder {
    /* display: grid;
    grid-template-columns: 1fr 1fr; */
    /* position: fixed; */
    /* bottom: 0; */
    left: 0;
    background-color: lightslategray;
    width: 100%;
}
.invoice-allocation {
    width: 50%;
    margin: 0 auto;
}
.allocation-total span{
    font-weight: bold;
}